<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      "Withdrawal History": "Histórico de Saques",
      Bitcoin: "Bitcoin",
      Bank: "Banco",
      Value: "Valor",
      Fee: "Taxa",
      Transfered: "Transferido",
      Transaction: "Transação",
      "Awaiting processing": "Aguardando processamento",
      Completed: "Completo",
      "View Transaction": "Ver Transação",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      "Withdrawal History": "Historia de Retirada",
      Bitcoin: "Bitcoin",
      Bank: "Banco",
      Value: "Valor",
      Fee: "Taxa",
      Transfered: "Transferido",
      Transaction: "Transacción",
      "Awaiting processing": "En espera de procesamiento",
      Completed: "Completo",
      "View Transaction": "Ver Transacción",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
  },
  data() {
    return {
      table: {
        heade: ["Data", "Tipo", "Conta", "Valor", "Status"],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getWithdrawals() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("wallet/withdrawal/token")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getWithdrawals();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <div>
            <h6 class="m-0 text-uppercase font-size-12">
              Carteira de Tokenização
            </h6>
            <h4 class="mb-0 font-size-18">{{ t("Withdrawal History") }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>Data</th>
                    <th>Tipo</th>
                    <th>Conta</th>
                    <th>Valor</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td>
                      {{ td.date }}
                    </td>
                    <td>
                      <strong v-if="td.type === 'bank'">CONTA BANCÁRIA</strong>
                      <strong v-else-if="td.type === 'pix'">PIX</strong>
                      <strong v-else-if="td.type === 'bitcoin'">BITCOIN</strong>
                    </td>
                    <td>
                      <template v-if="td.type == 'bank'">
                        {{ td.bank.bank.number }} - {{ td.bank.bank.name
                        }}<br />
                        <strong>Agência:</strong>
                        {{ td.bank.agency.number }}-{{ td.bank.agency.digit
                        }}<br />
                        <strong>Conta:</strong>
                        {{ td.bank.account.number }}-{{ td.bank.account.digit }}
                      </template>
                      <template v-else-if="td.type == 'pix'">
                        <strong>{{ td.pix.type }}</strong
                        ><br />
                        {{ td.pix.key }}
                      </template>
                      <template v-else-if="td.type == 'bitcoin'">
                        {{ td.bitcoin }}
                      </template>
                    </td>
                    <td>
                      {{ td.value | currency }}
                    </td>
                    <td>
                      <span
                        v-if="td.status === '0'"
                        class="badge badge-soft-warning font-size-12"
                      >
                        {{ t("Awaiting processing") }}
                      </span>
                      <span
                        v-else-if="td.status === '1'"
                        class="badge badge-soft-success font-size-12"
                      >
                        {{ t("Completed") }}
                      </span>
                      <span
                        v-else-if="td.status === '2'"
                        class="badge badge-soft-danger font-size-12"
                      >
                        {{ t("Canceled") }}
                      </span>
                      <span
                        v-else-if="td.status === '3'"
                        class="badge badge-soft-success font-size-12"
                      >
                        {{ t("Completed") }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
